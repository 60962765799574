<template>

  <validation-observer ref="simpleRules">
    <b-form
      @submit.prevent="postUserData"
      @reset.prevent="OnReset"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Full Name *"
            label-for="name"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="name"
            >
              <b-form-input
                id="name"
                v-model="editData.name"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Full Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Email *"
            label-for="email"
            label-cols-md="4"
          >
            <b-form-input
              id="email"
              v-model="editData.email"
              type="email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>

        <!-- Accept all image formats by IANA media type wildcard-->
        <b-col cols="12">
          <b-form-group
            label="Photos"
            label-for="avatar"
            label-cols-md="4"
          >
            <b-form-file
              id="avatar"
              accept="image/*"
              @change="onFileChanged"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Password"
            label-for="password"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="password"
            >
              <b-form-input
                id="password"
                v-model="editData.password"
                :state="errors.length > 0 ? false:null"
                type="password"
                placeholder="Password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Phone"
            label-for="phone"
            label-cols-md="4"
          >
            <b-form-input
              id="phone"
              v-model="editData.phone"
              type="text"
              placeholder="Phone"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Address"
            label-for="address"
            label-cols-md="4"
          >
            <b-form-textarea
              id="address"
              v-model="editData.address"
              type="text"
              placeholder="Address"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Gender"
            label-for="gender"
            label-cols-md="4"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="editData.gender"
                name="some-radios"
                value="1"
              >
                Male
              </b-form-radio>
              <b-form-radio
                v-model="editData.gender"
                name="some-radios"
                value="0"
              >
                Female
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Date Of Birth"
            label-for="date_of_birth"
            label-cols-md="4"
          >
            <b-form-datepicker
              id="date_of_birth"
              v-model="editData.date_of_birth"
              placeholder="Date Of Birth"
              local="en"
              initial-date=""
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Join Year"
            label-for="join_year"
            label-cols-md="4"
          >
            <v-select
              v-model="editData.join_year"
              placeholder="Please Select Year"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="yearData"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Profession"
            label-for="profession"
            label-cols-md="4"
          >
            <b-form-input
              id="profession"
              v-model="editData.profession"
              type="text"
              placeholder="Profession"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Marriage Status"
            label-for="marriage_status"
            label-cols-md="4"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="editData.marriage_status"
                name="marriage-radios"
                value="married"
              >
                Married
              </b-form-radio>
              <b-form-radio
                v-model="editData.marriage_status"
                name="marriage-radios"
                value="not married"
              >
                Not Married
              </b-form-radio>
              <b-form-radio
                v-model="editData.marriage_status"
                name="marriage-radios"
                value="divorced"
              >
                Divorced
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Date Of Marriage"
            label-for="date_of_marriage"
            label-cols-md="4"
          >
            <b-form-datepicker
              id="date_of_marriage"
              v-model="editData.date_of_marriage"
              placeholder="Date Of Marriage"
              local="en"
              initial-date="2000-06-01"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="church"
          >
            <b-form-group
              label="Church *"
              label-cols-md="4"
              :state="errors.length > 0 ? false:null"
            >

              <v-select
                v-model="editData.church"
                placeholder="Please Select Church"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="churchData"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Department"
            label-cols-md="4"
          >
            <v-select
              v-model="editData.department"
              placeholder="Please Select Department"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="departmentData"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Position"
            label-cols-md="4"
          >
            <v-select
              v-model="editData.position"
              placeholder="Please Select Position"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="positionData"
              label="name"
            />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BFormRadio, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormTextarea, BFormDatepicker,
  BFormFile,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormRadio,
    BFormFile,
    BFormTextarea,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      churchData: [],
      departmentData: [],
      positionData: [],
      editData: {},
      required,
      yearData: [
        2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
      ],
    }
  },
  mounted() {
    this.getUserData()
    this.getChurchData()
    this.getDepartmentData()
    this.getPositionData()
  },
  methods: {
    onFileChanged(event) {
      // eslint-disable-next-line prefer-destructuring
      this.editData.avatar = event.target.files[0]
    },
    getUserData() {
      axios
        .get(`/users/${this.$route.params.id}`)
        .then(res => {
          this.editData = res.data.data
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },
    getChurchData() {
      axios
        .get('/churches')
        .then(res => {
          this.churchData = res.data.data
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },
    getDepartmentData() {
      axios
        .get('/departments')
        .then(res => {
          this.departmentData = res.data.data
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },
    getPositionData() {
      axios
        .get('/positions')
        .then(res => {
          this.positionData = res.data.data
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },
    postUserData() {
      this.$refs.simpleRules.validate().then(success => {
        const postData = new FormData()
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(this.editData)) {
          postData.append(key, value)
        }

        // eslint-disable-next-line no-restricted-syntax
        // for (const pair of postData.entries()) {
        //   console.log(`${pair[0]}, ${pair[1]}`)
        // }
        // postData.append('_method', 'POST')
        postData.append('church_id', this.editData.church === undefined ? '' : this.editData.church.id)
        postData.delete('church')
        postData.append('department_id', this.editData.department === undefined ? '' : this.editData.department.id)
        postData.delete('department')
        postData.append('position_id', this.editData.position === undefined ? '' : this.editData.position.id)
        postData.delete('position')

        if (success) {
          this.$swal({
            title: 'Please Wait..',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            },
          }).then(
            axios.post(`/users/${this.$route.params.id}`, postData).then(res => {
              this.edit = {}
              // eslint-disable-next-line no-console
              console.log('res', res)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Your data has been saved.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                this.$router.push({ name: 'user-all' })
              })
            }),
          ).catch(err => {
            // eslint-disable-next-line no-console
            console.log('ERROR: ', err)
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.demo-inline-spacing > * {
    margin-top: 0.5rem;
}
</style>
